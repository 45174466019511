import { createRouter, createWebHistory } from 'vue-router'
// import Style from '@/views/StyleView.vue'
import Home from '@/views/HomeView.vue'
import { useAuthStore } from '@/stores/auth_store';
import ClientRouter from '@/router/clients'
import ManufactureRouter from '@/router/manufacture'
import ProductsRouter from '@/router/products'
import WarehouseRouter from '@/router/warehouse'
import CurrencyAccountsRouter from '@/router/currencyAccounts'
import RollCallsRouter from '@/router/rollCalls'

const routes = [
  // {
  //   meta: {
  //     title: 'Select style'
  //   },
  //   path: '/',
  //   name: 'style',
  //   component: Style
  // },
  { path: '/dashboard', redirect: '/' },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard'
    },
    path: '/',
    name: 'dashboard',
    component: Home
  },
  // {
  //   meta: {
  //     title: 'Tables'
  //   },
  //   path: '/tables',
  //   name: 'tables',
  //   component: () => import('@/views/TablesView.vue')
  // },
  {
    meta: {
      title: 'Forms'
    },
    path: '/forms',
    name: 'forms',
    component: () => import('@/views/FormsView.vue')
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/clients',
    children: ClientRouter
  },
  {
    path: '/manufacture',
    children: ManufactureRouter
  },
  {
    path: '/currency-accounts',
    children: CurrencyAccountsRouter
  },
  {
    path: '/products',
    children: ProductsRouter
  },
  {
    path: '/warehouse',
    children: WarehouseRouter
  },
  {
    path: '/roll-calls',
    children: RollCallsRouter
  },
  // {
  //   meta: {
  //     title: 'Ui'
  //   },
  //   path: '/ui',
  //   name: 'ui',
  //   component: () => import('@/views/UiView.vue')
  // },
  // {
  //   meta: {
  //     title: 'Responsive layout'
  //   },
  //   path: '/responsive',
  //   name: 'responsive',
  //   component: () => import('@/views/ResponsiveView.vue')
  {
    meta: {
      title: 'Cart'
    },
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/CartView.vue')
  },  // },
  {
    meta: {
      title: 'Login'
    },
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    meta: {
      title: 'Registration'
    },
    path: '/registration',
    name: 'registration',
    component: () => import('@/views/RegistrationView.vue')
  },
  // {
  //   meta: {
  //     title: 'Error'
  //   },
  //   path: '/error',
  //   name: 'error',
  //   component: () => import('@/views/ErrorView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/registration'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  // добавить проверку логина
  if (authRequired && !auth.authToken) {
    auth.returnUrl = to.fullPath;
    return '/login';
  }
});

export default router

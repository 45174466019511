export default [
    {
        meta: {
            title: 'Roll calls'
        },
        path: 'active',
        name: 'active-roll-calls-list',
        component: () => import('@/views/rollCalls/RollCallsAcitveListView.vue')
    },
    {
        meta: {
            title: 'Roll calls'
        },
        path: 'history',
        name: 'history-roll-calls-list',
        component: () => import('@/views/rollCalls/RollCallsHistoryListView.vue')
    },
]
export default [
    {
        meta: {
            title: 'Clients'
        },
        path: '',
        name: 'clients',
        component: () => import('@/views/clients/ClientsView.vue')
    },
    {
        meta: {
            title: 'Clients'
        },
        path: 'currency-accounts/',
        name: 'clients-currency-accounts',
        component: () => import('@/views/clients/ClientsAccountsView.vue')
    },
    {
        meta: {
            title: 'Clients'
        },
        path: 'currency-accounts/:id',
        name: 'clients-currency-account',
        component: () => import('@/views/clients/ClientsAccountView.vue')
    },
    {
        meta: {
            title: 'Clients'
        },
        path: 'credit-lines/',
        name: 'clients-credit-lines',
        component: () => import('@/views/clients/ClientsCreditLinesView.vue')
    },
    {
        meta: {
            title: 'Clients'
        },
        path: 'credit-lines/:id',
        name: 'clients-credit-line',
        component: () => import('@/views/clients/ClientsCreditLineView.vue')
    },
]
export default {
    menuAside: {
        menu: 'Menyu',
        dashboard: 'Boshqaruv paneli',
        clients: 'Mijozning',
        clientsList: "Mijozlar ro'yxati",
        credits: 'Kreditlar',
        rollCalls: 'Переклички',
        rollCallsActive: 'Активные',
        rollCallsHistory: 'История',
        accounts: 'Akkauntlar',
        products: 'Mahsulotlar',
        list: "Ro'yxat",
        categories: 'Kategoriyalar',
        manufacture: 'Ishlab chiqarish',
        incomes: 'Daromadlar',
        expenditures: 'Xarajatlar',
        transfers: 'Переводы',
        currencyAccounts: 'Accounts',
        warehouse: 'Warehouse',
        warehouseIncomes: 'Incomes',
        warehouseExpenditures: 'Expenditures',
        logout: 'Chiqish',
    },
    menuNavBar: {
        myprofile: 'Mening profilim',
        logout: 'Chiqish',
        lightdark: 'Och/Qora',
    },
    somethingWrong: 'Xatolik yuz berdi',
    paginator: '{numPages} dan {currentPageHuman} sahifa',
    cancel: 'Bekor qilish',
    apply: 'Apply',
    reset: 'Reset',
    selectoption: 'Tanlash',
    selectLabel: 'Tanlash uchun enter ni bosing',
    deselectLabel: 'Press enter to remove',
    selectedLabel: 'Selected',
    "Nothing's here…": "Bu yerda hech narsa yo'q…",
    'List is empty': 'Список пуст',
    'No elements found': 'Ничего не найдено. Попробуйте изменить запрос',
    profile: {
        title: 'Profil',
        avatar: 'Avatar',
        avatarHelp: 'Maksimal 500kb',
        upload: 'Yuklash',
        firstname: 'Ism',
        firstnameHelp: 'Majburiy. Sizning ismingiz',
        lastname: 'Familiya',
        lastnameHelp: 'Majburiy. Familiyangiz',
        phone: 'Telefon',
        phoneHelp: 'Sizning telefoningiz',
        submitLabel: 'Yuborish',
        timezone: 'Joriy vaqt mintaqasi',
        timezoneHelp: 'Vaqt mintaqangizni tanlang',
        language: 'Joriy til',
        languageHelp: 'Tilingizni tanlang',
        currentPassword: 'Joriy parol',
        currentPasswordHelp: 'Majburiy. Sizning joriy parolingiz',
        newPassword: 'Yangi parol',
        newPasswordHelp: 'Majburiy. Yangi parol',
        confirmNewPassword: 'Parolni tasdiqlang',
        confirmNewPasswordHelp: 'Majburiy. Yana bir bor yangi parol',
        usercardHello: 'Salom'
    },
    products: {
        newproduct: 'Yangi mahsulot',
        add: "Qo'shish",
        category: 'Toifa',
        product: 'Продукт',
        categoryHelp: 'Toifani tanlang',
        name: 'Ism',
        nameHelp: 'Mahsulot nomini kiriting',
        productlist: "Mahsulotlar ro'yxati",
        addcategory: 'Yangi mahsulot toifasi',
        categoryNameHelp: 'Yangi mahsulot toifasiga nom kiriting',
        categoryList: "Mahsulot toifalarining ro'yxati",
        parentcategory: 'Bosh toifa',
        price: 'Narxi',
        apply: "Qo'llash",
        "There are not enough products in stock": "Товара на складе не достаточно"
    },
    manufacture: {
        carAmount: 'Avtomobil narxi',
        user: 'Foydalanuvchi',
        timestamp: 'Vaqt belgisi',
        viewItems: "Elementlarni ko'rish",
        itemName: 'Element nomi',
        amount: 'Narxi',
        newCarIncome: 'Yangi avtomobil daromadi',
        add: "Qo'shish",
        carConfiguration: 'Avtomobil konfiguratsiyasi',
        carConfigurationHelp: 'konfiguratsiyani tanlang',
        amountHelp: 'Narxini kiriting',
        itemsInIncomeTitle: 'Daromaddagi elementlar',
        incomes: 'Daromadlar',
        addIncome: "Daromad qo'shish",
        expenditures: 'Xarajatlar',
        expenditure: 'Xarajatlar',
        date: 'Дата'
    },
    clients: {
        newClient: 'Yangi mijoz',
        add: "Qo'shish",
        firstName: 'Ismi',
        firstNameHelp: 'Ismini kiriting',
        lastName: 'Familiyasi',
        lastNameHelp: 'Familiyasini kiriting',
        phone: 'Telefon raqam',
        phoneHelp: 'Telefon raqamni kiriting',
        clientsTitle: 'Mijozlar',
        addClient: "Mijoz qo'shish",
        fullname: 'Ismi',
        company: 'Kompaniya',
        spent: 'Sarflangan',
        newClientCurrencyAccount: 'Mijozning yangi valyuta hisobi',
        client: 'Mijoz',
        clientHelp: 'Mijozni tanlang',
        currency: 'Valyuta',
        currencyHelp: 'Valyutani tanlang',
        clientsCreditsTitle: 'Mijoz kreditlari',
        duplicateCCAError: 'Ushbu valyuta bilan mijozning valyuta hisobini takrorlash',
        newClientCreditLine: 'Yangi mijoz kredit liniyasi',
        creditLineAmount: 'Miqdori',
        creditLineAmountHelp: 'Kredit liniyasi miqdorini kiriting',
        clientsCreditLinesTitle: 'Mijozning kredit liniyalari',
        addLine: "Liniya qo'shish",
        open: 'Ochish',
        isClose: 'Is close',
        isOpen: 'Ochiq',
        clientName: 'Mijozning ismi',
        credits: 'Kreditlar',
        credit: 'Kredit',
        debt: 'Qarz',
        loans: 'Kredit',
        clientsCurrencyAccounts: 'Mijozlarning valyuta hisoblari',
        addAccount: "Hisob qo'shish",
        balanceIsNotEnough: 'Balans yetarli emas',
        newMoneyTransfer: "Yangi pul o'tkazma",
        newMoneyIncome: 'Yangi pul daromadlari',
        newMoneyExpenditure: 'Yangi pul xarajatlari',
        debitAmount: 'Debet miqdori',
        debitAmountHelp: 'Iltimos, debet miqdorini kiriting',
        receiptCurrencyAccount: 'Valyuta tushumlari hisobi',
        receiptCurrencyAccountHelp: 'Iltimos, qabul qiluvchi valyutadagi hisobni tanlang',
        balance: 'Balans',
        amount: 'Miqdor',
        amountHelp: 'Miqdorni kiriting',
        receiptAmount: 'Qabul qilingan miqdor',
        receiptAmountHelp: 'Qabul qilingan miqdorni kiriting',
        clientCurrencyAccountTitle: 'Mijozning valyuta hisobi',
        moneyTransfers: "Pul o'tkazmalari",
        moneyIncomes: 'Pul daromadlari',
        moneyExpenditures: 'Pul xarajatlari',
        receipt: 'Kvitansiya',
        debit: 'Debet',
        timestamp: 'Vaqt belgisi',
        user: 'Foydalanuvchi',
        debitor: 'Qarzdor',
        recipient: 'Qabul qiluvchi',
        opponent: 'Qarzdor/Qabul qiluvchi',
        newCreditRepayment: "Yangi kredit to'lovi",
        currencyAccount: 'Valyuta hisobi',
        "Please choose currency account in client currency": 'Iltimos, mijoz valyutasida valyuta hisobini tanlang',
        "Please enter repayment amount": "Iltimos, to'lov miqdorini kiriting",
        "Exchange rate": "Ayirboshlash kursi",
        exchangeRateHelp: "Iltimos, ayirboshlash kursini kiriting. To'lov miqdori",
        "Client credit line": "Mijoz kredit liniyasi",
        "in": "ichida",
        addRepayment: "To'lovni qo'shish",
        "Credit line is closed": "Кредитная линия закрыта",
        "Credit line amount is not enogh": "Лимит кредитной линии не достаточен",
        "ClientDoesnotExists": "Клиент не существует"
    },
    cart: {
        addToCart: "Add to cart",
        price: "Price",
        amount: "Amount",
        total: "Total",
        ok: "Ok",
        removeFromCart: "Remove",
        add: 'Add',
        view: 'View',
        cartTitle: 'Cart',
        name: 'Name',
        clearCart: 'Clear',
        clearCartTitle: 'Clear cart',
        clearCartBody: 'Do you want to clear the cart?',
        pay: 'Оплатить',
        kassa: 'Наличные',
        credit: 'Кредит',
        prepayment: 'Предоплата',
        paymentType: 'Тип оплаты',
        "ProductsDoesNotExists": "Товар не существует"
    },
    currencyAccounts: {
        account: 'Счёт',
        "CurrencyAccounDoesnotExists": "Счёт не существует",
        title: 'Счета',
        name: 'Name',
        balance: 'Balance',
        view: 'Просмотр',
        newCurrencyAccount: 'Новый счёт',
        add: 'Add',
        currency: 'Valyuta',
        currencyHelp: 'Valyutani tanlang',
        moneyTransfers: "Pul o'tkazmalari",
        moneyIncomes: 'Pul daromadlari',
        moneyExpenditures: 'Pul xarajatlari',
        receipt: 'Kvitansiya',
        debit: 'Debet',
        timestamp: 'Vaqt belgisi',
        user: 'Foydalanuvchi',
        debitor: 'Qarzdor',
        recipient: 'Qabul qiluvchi',
        opponent: 'Qarzdor/Qabul qiluvchi',
        addAccount: "Hisob qo'shish",
        balanceIsNotEnough: 'Balans yetarli emas',
        newMoneyTransfer: "Yangi pul o'tkazma",
        newMoneyIncome: 'Yangi pul daromadlari',
        newMoneyExpenditure: 'Yangi pul xarajatlari',
        newFinanceExpenditure: 'Новый расход',
        debitAmount: 'Debet miqdori',
        debitAmountHelp: 'Iltimos, debet miqdorini kiriting',
        receiptCurrencyAccount: 'Valyuta tushumlari hisobi',
        receiptCurrencyAccountHelp: 'Iltimos, qabul qiluvchi valyutadagi hisobni tanlang',
        amount: 'Miqdor',
        amountHelp: 'Miqdorni kiriting',
        receiptAmount: 'Qabul qilingan miqdor',
        receiptAmountHelp: 'Qabul qilingan miqdorni kiriting',
        transfer: "Transfer",
        income: "Income",
        expenditure: "Expenditure",
        transfers: 'Переводы',
        receipts: 'Поступления',
        incomes: 'Доходы',
        withdrawals: 'Списания',
        expenditures: 'Расходы',
        category: 'Категория',
        comment: 'Комментарий',
        newFinanceExpenditureCategory: 'Новая категория'
    },
    warehouse: {
        productlist: 'Список товара на складе',
        productIncomelist: 'Список приходов',
        productExpenditurelist: 'Список расходов',
        receivingUser: 'Получатель',
        submittingUser: 'Отправитель'
    },
    rollCalls: {
        EnterMessage: 'Введите сообщение',
        ok: "Ok",
        start: "Начало",
        expires: "Длительность",
        tooFarFromTheOfficeError: ' You are too far from the office',
        question: 'Вопрос',
        answerState: 'Ответ',
        timestamp: 'Дата/время',
        history: 'История',
        locationRequired: 'Требуется локация'
    }
}
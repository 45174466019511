<script setup>
import { ref } from 'vue'
import { mdiEye, mdiTrashCan } from '@mdi/js'
import CardBoxModal from '@/components/CardBoxModal.vue'
import TableCheckboxCell from '@/components/TableCheckboxCell.vue'
import BaseButtons from '@/components/BaseButtons.vue'
import BaseButton from '@/components/BaseButton.vue'
import PaginatorComponent from '@/components/PaginatorComponent.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps({
  checkable: Boolean,
  items: { type: Array, default: new Array() }
})

const isModalActive = ref(false)
const isModalDangerActive = ref(false)
const checkedRows = ref([])
const itemsPaginated = ref([])

const remove = (arr, cb) => {
  const newArr = []

  arr.forEach((item) => {
    if (!cb(item)) {
      newArr.push(item)
    }
  })

  return newArr
}

const checked = (isChecked, client) => {
  if (isChecked) {
    checkedRows.value.push(client)
  } else {
    checkedRows.value = remove(checkedRows.value, (row) => row.id === client.id)
  }
}
</script>

<template>
  <CardBoxModal v-model="isModalActive" title="Sample modal">
    <p>Lorem ipsum dolor sit amet <b>adipiscing elit</b></p>
    <p>This is sample modal</p>
  </CardBoxModal>

  <CardBoxModal v-model="isModalDangerActive" title="Please confirm" button="danger" has-cancel>
    <p>Lorem ipsum dolor sit amet <b>adipiscing elit</b></p>
    <p>This is sample modal</p>
  </CardBoxModal>

  <table>
    <thead>
      <tr>
        <th v-if="checkable" />
        <th>{{ t('clients.fullname') }}</th>
        <th>{{ t('clients.company') }}</th>
        <th>{{ t('clients.phone') }}</th>
        <th>{{ t('clients.spent') }}</th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr v-for="client in itemsPaginated" :key="client.id">
        <TableCheckboxCell v-if="checkable" @checked="checked($event, client)" />
        <td :data-label="t('clients.fullname')">
          {{ client.fullname }}
        </td>
        <td :data-label="t('clients.company')">
          {{ client.company_name }}
        </td>
        <td :data-label="t('clients.phone')">
          {{ client.phone_number }}
        </td>
        <td :data-label="t('clients.spent')" class="lg:w-32">
          <progress class="flex w-2/5 self-center lg:w-full" max="100" :value="client.spent">
            {{ client.progress }}
          </progress>
        </td>
        <td class="before:hidden lg:w-1 whitespace-nowrap">
          <BaseButtons type="justify-start lg:justify-end" no-wrap>
            <BaseButton color="info" :icon="mdiEye" small @click="isModalActive = true" />
            <!-- <BaseButton
              color="danger"
              :icon="mdiTrashCan"
              small
              @click="isModalDangerActive = true"
            /> -->
          </BaseButtons>
        </td>
      </tr>
    </tbody>
  </table>
  <paginator-component v-model="itemsPaginated" :items="items" />
</template>

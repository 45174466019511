import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers/fetch-wrapper';

// import router from '@/router'

const baseUrl = `${import.meta.env.VITE_BASE_URL}`;

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        username: null,
        firstName: null,
        lastName: null,
        telefon: null,
        // userEmail: null,
        userAvatar: null,
    }),
    actions: {
        setUser(user) {
            this.username = user.username;
            this.firstName = user.first_name;
            this.lastName = user.last_name;
            // this.userEmail = user.email;
            this.telefon = user.telefon;
        },
        async changeUser(user) {
            return new Promise((resolve, reject) => {
                fetchWrapper.patch(`${baseUrl}/auth/users/me/`, user).then((user) => {
                    this.setUser(user);
                    resolve('ok')
                }).catch((error) => {
                    reject(error)
                });
            }
            )
        },
        async setNewPassword(current_password, new_password) {
            return new Promise((resolve, reject) => {
                fetchWrapper.post(`${baseUrl}/auth/users/set_password/`, { current_password, new_password }).then(() => {
                    resolve('ok')
                }).catch((error) => {
                    reject(error)
                });
            }
            )
        },
        async getUsersList(query = null) {
            let url = new URL(`${baseUrl}/auth/users/`)
            url.searchParams.set('limit', '100000')
            if (query != null) {
                try {
                    query.forEach((value, key) => url.searchParams.set(key, value))
                } catch (err) {
                    console.log(err, query)
                }
            }
            return new Promise((resolve, reject) => {
                fetchWrapper.get(new String(url)).then((response) => {
                    resolve(response.results)
                }).catch((error) => {
                    reject(error)
                });
            }
            )
        }
    },
    getters: {
        userName: (state) => {
            if (state.firstName && state.lastName) {
                return `${state.firstName} ${state.lastName}`
            } else if (state.username) {
                return state.username
            } else { return '' }
        },
        avatar: (state) => {
            if (state.userAvatar) {
                return state.userAvatar
            }
            const userName = `${state.username}`
            return `https://api.dicebear.com/7.x/avataaars/svg?seed=${userName.replace(
                /[^a-z0-9]+/gi,
                '-'
            )}`
        }
    },
});
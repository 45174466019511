export default [
    {
        meta: {
            title: 'Warehouse'
        },
        path: 'items-in-warehouse/',
        name: 'item-in-warehouse',
        component: () => import('@/views/warehouse/WarehouseListView.vue')
    },
    {
        meta: {
            title: 'Warehouse'
        },
        path: 'incomes/',
        name: 'warehouse-incomes',
        component: () => import('@/views/warehouse/WarehouseListIncomesView.vue')
    },
    {
        meta: {
            title: 'Warehouse'
        },
        path: 'expenditures/',
        name: 'warehouse-expenditures',
        component: () => import('@/views/warehouse/WarehouseListExpendituresView.vue')
    },
]
export default [
    {
        meta: {
            title: 'Currency accounts'
        },
        path: '',
        name: 'currency-accounts-list',
        component: () => import('@/views/currencyAccounts/ListView.vue')
    },
    {
        meta: {
            title: 'Currency accounts'
        },
        path: 'expenditures/',
        name: 'currency-accounts-expenditures',
        component: () => import('@/views/currencyAccounts/ExpendituresView.vue')
    },
    {
        meta: {
            title: 'Currency accounts'
        },
        path: 'incomes/',
        name: 'currency-accounts-incomes',
        component: () => import('@/views/currencyAccounts/IncomesView.vue')
    },
    {
        meta: {
            title: 'Currency accounts'
        },
        path: 'withdrawals/',
        name: 'currency-accounts-withdrawals',
        component: () => import('@/views/currencyAccounts/WithdrawalsView.vue')
    },
    {
        meta: {
            title: 'Currency accounts'
        },
        path: 'receipts/',
        name: 'currency-accounts-receipts',
        component: () => import('@/views/currencyAccounts/ReceiptsView.vue')
    },
    {
        meta: {
            title: 'Currency accounts'
        },
        path: 'transfers/',
        name: 'currency-accounts-transfers',
        component: () => import('@/views/currencyAccounts/TransfersView.vue')
    },
    {
        meta: {
            title: 'Currency accounts'
        },
        path: '/:id',
        name: 'currency-account',
        component: () => import('@/views/currencyAccounts/AccountView.vue')
    },
]
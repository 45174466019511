export default [
    {
        meta: {
            title: 'Manufacture incomes'
        },
        path: 'incomes/',
        name: 'manufacture-incomes',
        component: () => import('@/views/manufacture/IncomesView.vue')
    },
    {
        meta: {
            title: 'Manufacture expenditures'
        },
        path: 'expenditures/',
        name: 'manufacture-expenditures',
        component: () => import('@/views/manufacture/ExpendituresView.vue')
    },
    {
        meta: {
            title: 'Manufacture expenditures'
        },
        path: 'expenditures/:id',
        name: 'manufacture-expenditure',
        component: () => import('@/views/manufacture/ExpenditureView.vue')
    },
]
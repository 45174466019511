import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { fetchWrapper } from '@/helpers/fetch-wrapper';

const baseUrl = `${import.meta.env.VITE_BASE_URL}`;


export const useCartStore = defineStore('cart', () => {
  const cart = ref(JSON.parse(localStorage.getItem('cart')))
  if (!cart.value || cart.value === "undefined") {
    cart.value = emptyCart()
    saveCart()
  }
  const itemsInCart = computed(() => {
    let items = []
    if (cart.value.items.length) {
      cart.value.items.forEach((item) => items.push(item.id))
    }
    return items
  })


  function calcItemsInCart() {
    let items = []
    if (cart.value.items.length) {
      cart.value.items.forEach((item) => items.push(item.id))
    }
    return items
  }

  function calcTotal() {
    let total = 0.0
    cart.value.items.forEach((item) => total += parseFloat(item.total))
    cart.value.total = total
  }
  function emptyCart() { return { items: [], total: 0 } }
  function clearCart() {
    cart.value = emptyCart()
    saveCart()
  }
  function saveCart() {
    calcTotal()
    localStorage.setItem('cart', JSON.stringify(cart.value))
  }
  const cartLength = computed(() => cart.value.items.length)

  function addItemToCart(item) {
    if (!isItemInCart(item.id)) {
      cart.value.items.push(item)
    } else {
      const idx = cart.value.items.findIndex((oldItem) => oldItem.id == item.id)
      cart.value.items[idx] = item
    }
    saveCart()

  }
  function getItemFromCart(itemId) {
    return cart.value.items.find((item) => item.id == itemId)
  }
  function removeItemFromCart(itemId) {
    cart.value.items = cart.value.items.filter((item) => item.id != itemId)
    saveCart()
  }
  function isItemInCart(itemId) {
    return itemsInCart.value.includes(itemId)
  }
  async function payForTheCart(sale) {
    let form = { products: [], total: cart.value.total }
    cart.value.items.forEach((item) => {
      form.products.push(
        {
          product_id: item.id,
          amount: item.amount,
          price: item.price,
          total: item.total
        }
      )
    })
    Object.assign(sale, form);
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/manufacture/manufacture-sale-cart/`, sale).then(() => {

        clearCart()
        resolve('ok')
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }

  return {
    cart,
    cartLength,
    itemsInCart,
    addItemToCart,
    removeItemFromCart,
    getItemFromCart,
    clearCart,
    isItemInCart,
    calcItemsInCart,
    payForTheCart
  }
})

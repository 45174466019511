export default [
    {
        meta: {
            title: 'Products'
        },
        path: '',
        name: 'products-list',
        component: () => import('@/views/products/ProductsListView.vue')
    },
    {
        meta: {
            title: 'Clients'
        },
        path: 'categories',
        name: 'products-category-list',
        component: () => import('@/views/products/ProductsCategoryListView.vue')
    },
]
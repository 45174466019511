<script setup>
import {
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronLeft,
  mdiChevronRight
} from '@mdi/js'
import { computed, defineEmits, ref, onBeforeMount, watch } from 'vue'
import BaseLevel from '@/components/BaseLevel.vue'
import BaseButtons from '@/components/BaseButtons.vue'
import BaseButton from '@/components/BaseButton.vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const props = defineProps({
  perPage: { type: Number, default: 10 },
  modelValue: { type: Array, required: true },
  items: { type: Array, required: true },
  changeRoute: { type: Boolean, default: true }
})
const searchParams = new URLSearchParams(route.query)
const currentPage = ref(0)

const numPages = computed(() => Math.ceil(props.items.length / props.perPage))
const pagesList = computed(() => {
  var pagesList = []

  for (let i = 0; i < numPages.value; i++) {
    let page = undefined
    if (i == numPages.value - 1 && i == currentPage.value) {
      page = { label: i + 1, icon: '', page: i }
    } else if (i == currentPage.value) {
      page = { label: i + 1, icon: '', page: i }
    } else if (i == 0) {
      page = { label: '', icon: mdiChevronDoubleLeft, page: i }
    } else if (i == currentPage.value - 1) {
      page = { label: '', icon: mdiChevronLeft, page: i }
    } else if (i == numPages.value - 1) {
      page = { label: '', icon: mdiChevronDoubleRight, page: i }
    } else if (i == currentPage.value + 1) {
      page = { label: '', icon: mdiChevronRight, page: i }
    }
    if (page) {
      pagesList.push(page)
    }
  }

  return pagesList
})

const emit = defineEmits(['update:model-value', 'click-last-page'])
const currentPageHuman = computed(() => currentPage.value + 1)
const viewItems = computed(() =>
  props.items.slice(props.perPage * currentPage.value, props.perPage * (currentPage.value + 1))
)
function changeCurrentPage(numPage) {
  currentPage.value = numPage
  emit('update:model-value', viewItems.value)
  if (props.changeRoute) {
    router.replace({ query: Object.assign({ ...route.query }, { page: currentPage.value + 1 }) })
  }
  if (currentPage.value == numPages.value - 1) {
    emit('click-last-page')
  }
}
watch(
  () => props.items,
  () => {
    currentPage.value = 0
    changeCurrentPage(currentPage.value)
  }
)
function validateCurrentPage() {
  if (searchParams.has('page')) {
    if (/^-?\d+$/.test(searchParams.get('page'))) {
      currentPage.value = parseInt(searchParams.get('page')) - 1
    } else {
      currentPage.value = 0
    }
  } else {
    currentPage.value = 0
  }
  if (currentPage.value < 0) {
    currentPage.value = 0
  } else if (currentPage.value > numPages.value - 1) {
    currentPage.value = numPages.value - 1
  }
}
onBeforeMount(() => {
  validateCurrentPage()
  changeCurrentPage(currentPage.value)
})
</script>

<template>
  <div v-if="numPages > 1" class="p-3 lg:px-6 border-t border-gray-100 dark:border-slate-800">
    <BaseLevel>
      <BaseButtons>
        <BaseButton
          v-for="page in pagesList"
          :key="page.page"
          :active="page.page === currentPage"
          :label="page.label"
          :icon="page.icon"
          :color="page.page === currentPage ? 'lightDark' : 'whiteDark'"
          small
          @click="changeCurrentPage(page.page)"
        />
      </BaseButtons>
      <small>{{ t('paginator', { currentPageHuman, numPages }) }}</small>
    </BaseLevel>
  </div>
</template>